export default {
	namespaced: true,
	state: () => ({
		loading: false,
		input: [],
		output: [],
		selected_token: JSON.parse(localStorage.getItem("selected_token")) || {
			address: "So11111111111111111111111111111111111111112",
			image_src: "/img/tokens/solana.png",
			name: "Solana",
			symbol: "SOL"
		},
	}),
	mutations: {
		add_input(state, input) {
			state.input.push(input)
		},
		add_output(state, output) {
			state.output.push(output)
		},
		set_input_amount(state, data) {
			state.input[data.key].amount = data.amount
		},
		set_output_amount(state, data) {
			state.output[data.key].amount = data.amount
		},
		set_input_quote(state, data) {
			console.log("set_input_quote", data)
			for (let i = 0; i < state.input.length; i++)
				if (state.input[i].type === data.type && state.input[i].data.nft.id === data.id) {
					state.input[i].data.quote = data.quote
				}
		},
		set_output_quote(state, data) {
			console.log("set_output_quote", data)
			for (let i = 0; i < state.output.length; i++)
				if (state.output[i].type === data.type && state.output[i].data.nft.id === data.id) {
					state.output[i].data.quote = data.quote
				}
		},

		del_input(state, idx) {
			state.input.splice(idx, 1)
		},

		del_output(state, idx) {
			state.output.splice(idx, 1)
		},
		set_loading(state, loading) {
			state.loading = loading;
		},
		clear(state) {
			state.input = []
			state.output = []
			this.loading = false
		}
	}
}