<template>
	<footer class="py-1 small grey container-fluid">
		<div class="row">
			<div class="d-none d-md-block col text-start order-first">
				<span>v1.0.0 BETA</span>
			</div>
			<div class="col-sm-12 col-md-auto order-last order-md-1"><a target="_blank" href="https://alphabatem.com">Powered By AlphaBatem Labs</a></div>
			<div class="d-none d-md-block col text-end order-1 order-md-last">
				<div class="d-inline-block">
					<img draggable="false" src="/img/tokens/solana.png" height="16px" class="me-2 img-token">
					<span>${{ $store.state.util.usdPrice > 0 ? $store.state.util.usdPrice.toFixed(2) : '-' }}</span>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>
a {
	text-decoration: none;
	text-underline: none;
	color: grey;
	transition: color 0.3s ease-in-out;
	animation: flicker 10s infinite;
}

a:hover {
	color: white;
}


@keyframes flicker {
	80% {
		color: grey;
	}
	90% {
		color: white;
	}
	100% {
		color: grey;
	}
}
</style>