<template>
	<WalletMultiButton :key="$store.state.wallet.address" :data-key="$store.state.wallet.address" :openOnboardingUrls="false" :connecting="connecting" @copy="onCopy" @error="onWalletError" @connect="onConnected"
			@disconnect="onDisconnect"
			:wallets="wallets"
			dark></WalletMultiButton>
</template>

<script>
import {useWallet, WalletMultiButton} from "@alphabatem/vue2-wallet-adapter";
import {BackpackWalletAdapter} from "@solana/wallet-adapter-backpack"
import {CoinbaseWalletAdapter} from "@solana/wallet-adapter-coinbase"
import {PhantomWalletAdapter} from "@solana/wallet-adapter-phantom"
import {SolflareWalletAdapter} from "@solana/wallet-adapter-solflare"

export default {
	name: "MultiWallet",
	components: {
		WalletMultiButton
	},
	props: {
		button_text: {
			type: String,
			default: function () {
				return "Connect Wallet"
			}
		}
	},
	data() {
		return {
			key: 0,
			attempts: 0,
			connecting: false,
			wallet: null,
			wallets: [
				new SolflareWalletAdapter(),
				new PhantomWalletAdapter(),
				new BackpackWalletAdapter(),
				new CoinbaseWalletAdapter(),
			]
		}
	},
	watch: {
		'wallet.wallet': {
			handler: function () {
				console.log("change",this.wallet.wallet)
				if (this.wallet.connected) {
					if (this.wallet.publicKey.toString() !== this.$store.state.wallet.address) {
						console.log("Wallet changed", this.wallet.publicKey.toString())
						this.attempts = 0;

						if (this.$store.state.wallet.connected) {
							this.$toastr.s(this.wallet.publicKey.toString(), "Wallet Changed")
							this.$store.commit("swap/clear")
						}
						this.onConnected()
						return;
					}
					console.log("Wallet connected")
					return;
				}

				if (this.connecting) {
					console.log("Wallet is connecting")
					return;
				}
				this.attemptConnect()
			},
			deep: true
		},
		'$store.state.wallet.connected'() {
			this.$forceUpdate()
		},
	},
	methods: {
		onDisconnect() {
			this.$toastr.e("Wallet disconnected")
		},

		onCopy(addr) {
			this.$toastr.s(addr, "Address copied to clipboard")
		},

		onWalletError(e) {
			this.$toastr.e(e, "Wallet Error")
		},

		onConnected() {
			if (!this.$store.state.wallet.connected)
				this.$toastr.s("Wallet connected!")

			this.key++
			this.connecting = false
			this.attempts = 0
			console.log("Connected", this.wallet.publicKey.toString())
			this.$emit("wallet-connected", this.wallet.publicKey.toString()); //Fire connected event
			this.$store.commit('wallet/set_wallet_address', this.wallet.publicKey.toString());
			this.$store.commit('wallet/set_wallet_connected', true);
			this.$emit("connected", true)
		},
		attemptConnect() {
			if (!this.wallet.wallet || this.attempts > 3)
				return

			console.log("Wallet not connected & not connecting", this.wallet, this.attempts)
			this.attempts++
			this.wallet.connect().catch(e => {
				console.error("Unable to connect to wallet", e)
			}).finally(() =>{
				this.connecting = false
			})
		}
	},
	mounted() {
		this.wallet = useWallet()
		this.attemptConnect()

		if (this.wallet.connected) {
			this.onConnected()
		}
	}
}
</script>

<style scoped>
</style>
