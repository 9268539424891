<template>
	<div id="app">
		<Navbar></Navbar>
		<div id="inner">
			<div id="view pb-sm-5">
				<router-view/>
			</div>
			<portal-target name="body"></portal-target>
			<Footer></Footer>
		</div>
	</div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import * as web3 from "@solana/web3.js";
import {TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {UserAssets} from "@/assets/user_assets";
import {getCollectionPricing, getCollections, postApi, setBaseURI} from "@alphabatem/nft-amm-sdk";

export default {
	components: {Footer, Navbar},
	data() {
		return {
			lastPriceFetch: 0,
			ua: null,
			con: null,
		}
	},
	watch: {
		'$store.state.wallet.connected': function () {
			this.onWalletConnected()
		},
		'$store.state.util.reload_assets': function () {
			console.log("Reloading user assets")
			this.ua.reloadAccountInfo(this.$store.state.wallet.address, () => {
				this.$store.commit("nft/set_owned", this.ua.ownedNFT)
				this.$toastr.s("NFT Assets Refreshed")
			})
		},
		'$store.state.util.token_price_lookup': function () {
			this.getUSDPrice()
		},
		'$store.state.util.reload_tokens': function () {
			this.getUSDPrice(true)
		},
	},
	methods: {
		getUSDPrice(force = false) {
			if (!force && Date.now() - this.lastPriceFetch < 10000)
				return

			this.lastPriceFetch = Date.now()
			const priceIDs = []
			if (priceIDs.length === 0) {
				priceIDs.push("So11111111111111111111111111111111111111112", "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v")
			}
			priceIDs.push(...Object.keys(this.$store.state.util.token_price_lookup))

			fetch(`https://quote-api.jup.ag/v4/price?ids=${priceIDs.slice(0, 100).join(',')}&vs_currencies=usd`).then(r => r.json()).then(j => {
				this.$store.commit("util/set_usd_price", j.data["So11111111111111111111111111111111111111112"].price)
				this.$store.commit("util/set_prices", j.data)
			}).catch(() => {
				this.$toastr.e("Please try again later", "Unable to fetch jupiter pricing")
			})
		},

		getNFTCollectionPrices() {
			getCollectionPricing().then(j => {
				this.$store.commit("util/set_nft_prices", j)
			}).catch(() => {
				this.$toastr.e("Please try again later", "Unable to fetch pricing")
			})
		},

		getNFTCollections() {
			getCollections().then(r => {
				this.$store.commit("util/set_collections", r)
			}).catch(() => {
				this.$toastr.e("Please try again later", "Unable to fetch collections")
			})
		},

		getUserAssets() {
			console.log("Fetching user assets")
			this.ua.setLoadDangerousNFTs(true)

			this.ua.getAccountInfo(this.$store.state.wallet.address, () => {
				this.$store.commit("nft/set_owned", this.ua.ownedNFT)
			})
		},

		async getTokenBalances() {
			const tokens = await this.con.getParsedTokenAccountsByOwner(new web3.PublicKey(this.$store.state.wallet.address), {
				programId: TOKEN_PROGRAM_ID,
			});

			const sorted = tokens.value.filter(r => {
				this.setTokenCache(r.account.data.parsed.info.mint, r.account.data.parsed.info.tokenAmount.uiAmount)
				return r.account.data.parsed.info.tokenAmount.uiAmount > 0
			})
			this.$store.commit("util/set_owner_tokens", sorted)
			this.getUSDPrice()
		},

		async getSolBalance() {
			const solBalance = await this.con.getBalance(new web3.PublicKey(this.$store.state.wallet.address))
			this.setTokenCache("So11111111111111111111111111111111111111112", solBalance / Math.pow(10, 9))
		},

		setTokenCache(address, amount) {
			if (amount !== 1 && amount !== 0) {
				// console.log("Set token cache", address,amount)
				this.$store.commit("util/set_token_price_lookup", address)
			}

			this.$store.commit("util/set_token_balance", {
				address: address,
				balance: amount
			})
		},

		/**
		 * Notify of wallet connection
		 */
		sendWalletConnect() {
			postApi("users/connect", {walletAddress: this.$store.state.wallet.address})
		},

		onWalletConnected() {
			this.sendWalletConnect()

			this.getSolBalance()
			this.getUserAssets()
			this.getTokenBalances().then(() => {
				this.getUSDPrice()
			})
		}
	},
	mounted() {
		this.con = new web3.Connection("https://solana-mainnet.g.alchemy.com/v2/W2UhJ2iM16K0RHe8D69JK3u-mpOi-qbx", {})
		this.ua = new UserAssets("https://solana-mainnet.g.alchemy.com/v2/W2UhJ2iM16K0RHe8D69JK3u-mpOi-qbx", {
			commitment: "confirmed"
		})

		if (window.location.host.indexOf("localhost") > -1) {
			setBaseURI("http://localhost:9094/v1")
		}

		if (this.$store.state.wallet.connected) {
			this.onWalletConnected()
		}

		this.getNFTCollections() //Get our base collection details
		this.getNFTCollectionPrices()

		setInterval(() => {
			this.getUSDPrice()
			this.getNFTCollectionPrices()
		}, 60000)
	}
}
</script>


<style>
</style>