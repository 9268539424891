export default {
	namespaced: true,
	state: () => ({
		reload_assets: 0,
		reload_tokens: 0,
		txnCount: 0,
		recentTransactions: JSON.parse(localStorage.getItem("recent_transactions")) || {},
		ownerTokens: [],
		token_price_lookup: {},
		defaultExplorer: `https://explorer.solana.com`,
		usdPrice: 0,
		nftPrices: {},
		prices: {},
		collections: [],
		tokens: [],
		tokenBalances: JSON.parse(localStorage.getItem("token_balances")) || {}
	}),
	mutations: {
		set_usd_price(state, price) {
			state.usdPrice = price;
		},
		set_prices(state, prices = {}) {
			state.prices = prices
		},
		set_token_price(state, p = {address: "", price: 0}) {
			console.log("Token price set", state.prices)
			state.prices[p.address] = p.price
			state.reload_tokens++
		},
		set_nft_prices(state, prices = {}) {
			state.nftPrices = prices
		},
		set_collections(state, collections = []) {
			state.collections = collections
		},
		set_tokens(state, tokens = []) {
			state.tokens = tokens
		},
		set_token_balances(state, prices = {}) {
			state.tokenBalances = prices
		},
		set_token_balance(state, data = {address: "", balance: 0}) {
			state.tokenBalances[data.address] = data.balance
			localStorage.setItem("token_balances", JSON.stringify(state.tokenBalances))
		},
		set_token_price_lookup(state, address) {
			state.token_price_lookup[address] = true
			state.reload_tokens++
		},
		set_owner_tokens(state, tokens) {
			state.ownerTokens = tokens
		},
		reload_assets(state) {
			state.reload_assets++
		},
		add_recent_transaction(state, {sig, txn = null}) {
			state.recentTransactions[sig] = txn
			if (state.recentTransactions.length > 50)
				state.recentTransactions = state.recentTransactions.slice(state.recentTransactions.length - 50)

			localStorage.setItem("recent_transactions", JSON.stringify(state.recentTransactions))
			state.txnCount++
		}
	}
}
