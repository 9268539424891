import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
	},
	{
		path: '/swap',
		name: 'swap',
		component: () => import(/* webpackChunkName: "swap" */ '../views/SwapView.vue')
	},
	{
		path: '/swap/multi',
		name: 'Multi Swap',
		component: () => import(/* webpackChunkName: "multi_swap" */ '../views/MultiSwapView.vue')
	},
	{
		path: '/swap/multi/confirm',
		name: 'Multi Swap Confirm',
		component: () => import(/* webpackChunkName: "multi_swap" */ '../views/MultiSwapConfirmView.vue')
	},
	{
		path: '/stats',
		name: 'stats',
		component: () => import(/* webpackChunkName: "stats" */ '../views/StatsView.vue')
	},
	{
		path: '/collections',
		name: 'collections',
		component: () => import(/* webpackChunkName: "collections" */ '../views/collection/CollectionIndex.vue')
	},
	{
		path: '/collections/:id',
		name: 'collections',
		component: () => import(/* webpackChunkName: "collections" */ '../views/collection/CollectionShow.vue')
	},
	{
		path: '/referrals',
		name: 'referral',
		component: () => import(/* webpackChunkName: "referral" */ '../views/referral/ReferralDashboard.vue')
	},
	{
		path: '/docs',
		name: 'docs',
		component: () => import(/* webpackChunkName: "docs" */ '../views/DocsView.vue')
	},
	{
		path: '/faq',
		name: 'faq',
		component: () => import(/* webpackChunkName: "docs" */ '../views/FAQView.vue')
	},
	{
		path: '/admin/stats',
		name: 'admin',
		component: () => import(/* webpackChunkName: "admin" */ '../views/admin/DashboardView.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
