<template>
	<nav id="navbar" class="navbar navbar-expand-lg navbar-dark bg-dark pe-none px-0 px-md-5">
		<router-link to="/" class="navbar-brand pe-all"><img draggable="false" class="ms-3" height="40" alt="" src="/img/icons/liquify_nft.png">
			<span class="beta-badge sup small badge bg-primary">BETA</span></router-link>
		<button @click="toggleNav" class="navbar-toggler pe-all ms-auto me-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto">
				<li class="nav-item pop">
					<router-link class="nav-link ms-5 ps-5" to="/">Home <span class="sr-only">(current)</span></router-link>
				</li>
				<li class="nav-item pop">
					<router-link to="/swap" class="nav-link mx-4">Swap</router-link>
				</li>
				<!--				<li class="nav-item pop">-->
				<!--					<router-link disabled="disabled" title="Coming Soon!" to="/lend" class="nav-link grey">Lend</router-link>-->
				<!--				</li>-->
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
							@click="() => toggleDropdown()">
						More
					</a>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown" @mouseleave="() => toggleDropdown()">
						<router-link to="/docs" class="dropdown-item">Docs</router-link>
						<!--						<router-link to="/stats" class="dropdown-item">Stats</router-link>-->
						<router-link to="/referrals" class="dropdown-item">Referrals</router-link>
						<router-link to="/faq" class="dropdown-item">FAQ</router-link>
					</div>
				</li>
			</ul>
			<ul class="navbar-nav">
				<li class="nav-item">
					<MultiWallet v-if="showWallet"></MultiWallet>
					<p v-else class="mw-180 mb-0"></p>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script>
import MultiWallet from "@/components/wallet/MultiWallet";

export default {
	name: "Navbar",
	components: {MultiWallet},
	data() {
		return {
			navContent: null,
			navContainer: null,
		}
	},
	computed: {
		showWallet: function () {
			if (this.$store.state.wallet.connected)
				return true

			return this.$route.path !== "" && this.$route.path !== "/"
		}
	},
	methods: {
		toggleNav: function () {
			this.navContent.classList.toggle("show")
			this.navContainer.classList.toggle("show")
		},
		toggleDropdown: function () {
			const ddM = document.getElementsByClassName("dropdown-menu")[0]
			ddM.classList.toggle("show")
			// if (show)
			// 	ddM.classList.add("show")
			// else
			// 	ddM.classList.remove("show")
		}
	},
	beforeRouteLeave() {
		this.toggleDropdown(false)
	},
	mounted() {
		this.navContent = document.getElementById("navbarSupportedContent")
		this.navContainer = document.getElementById("navbar")
	}
}
</script>

<style scoped>
nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	background: transparent !important;
}

.nav-item {
	transition: all 0.3s ease;
}

.pe-all, .nav-item {
	pointer-events: all;
}

.nav-item.pop:active {
	transform: scale(0.95);
}

#navbar.show {
	background: rgba(0, 0, 0, 1) !important;
}

.navbar {
	justify-content: unset !important;
}

.navbar-collapse.show {
	background: rgba(0, 0, 0, 1);
	padding-bottom: 10px;
}

.navbar-brand {
	position: relative;
}

.beta-badge {
	position: absolute;
	font-size: 0.5em;
	line-height: 1em;
	margin-left: 0.5rem;
	background: var(--color-primary) !important;
	color: black;
	font-weight: bold;
}

.mw-180 {
	width: 100%;
	max-width: 180px;
	min-width: 180px;
}

</style>