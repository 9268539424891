import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import VueToastr from "vue-toastr";
import VueTour from 'vue-tour'

import "@fortawesome/fontawesome-free/css/all.css"
import "./assets/css/wallet_adapter.css";
import "@alphabatem/solana-sale-toast/dist/css/solana_toast.css";

import "./assets/css/base_theme.css";
import "./assets/css/theme.css";
import "./assets/css/tour_theme.css";

import store from './store'


import "vue-tour/dist/vue-tour.css"
Vue.use(VueTour)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Vue.use(VueToastr, {
	defaultPosition: "toast-bottom-right",
	timeout: 2000,
	preventDuplicates: true,
});

Vue.config.productionTip = false

new Vue({
	router, store, render: h => h(App)
}).$mount('#app')
