export default {
	namespaced: true,
	state: () => ({
		connected: false,
		address: null,
	}),
	mutations: {
		set_wallet_connected(state, connected) {
			state.connected = connected;
		},

		set_wallet_address(state, addr) {
			state.address = addr;
		},

		clear_wallet(state) {
			state.connected = false
			state.address = ""
		},
	}
}
