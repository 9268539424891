export default {
	namespaced: true,
	state: () => ({
		owned: JSON.parse(localStorage.getItem('owned_nfts')) || []
	}),
	mutations: {
		set_owned(state, owned) {
			state.owned = owned;
			localStorage.setItem(`owned_nfts`, JSON.stringify(state.owned))
		},
	}
}
